import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const Imprint = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">Privacy Policy</h2>
                  <p className="gr-text-8 mb-5">
                    We display our legal texts online in English. For other
                    languages or understanding problems, please contact
                    info@trustify.ch. Thanks.
                  </p>
                  <h3 className="gr-text-7 mb-8">
                    <a href="https://trustify.ch/">Back to startpage</a>
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                      The responsible party within the meaning of the data
                      protection laws, in particular the EU General Data
                      Protection Regulation (GDPR / DSGVO), is:<br></br>
                      <br></br>
                      NAVEST GmbH<br></br>
                      Joël Ambord<br></br>
                      Gliserallee 144<br></br>
                      3902 Brig-Glis<br></br>
                      <br></br>
                      Phone: +41 27 558 09 09<br></br>
                      E-Mail: info@trustify.ch<br></br>
                      Website: https://trustify.ch/<br></br>
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">
                      General note
                    </h3>
                    <p className="gr-text-9">
                      Based on Article 13 of the Swiss Federal Constitution and
                      the data protection provisions of the Swiss Confederation
                      (Data Protection Act, DSG), every person has the right to
                      protection of their privacy as well as protection against
                      misuse of their personal data. The operators of these
                      pages take the protection of your personal data very
                      seriously. We treat your personal data confidentially and
                      in accordance with the legal data protection regulations
                      as well as this privacy policy.
                      <br></br>
                      <br></br>In cooperation with our hosting providers, we
                      make every effort to protect the databases as well as
                      possible against unauthorized access, loss, misuse or
                      falsification.
                      <br></br>
                      <br></br>We would like to point out that data transmission
                      on the Internet (e.g. communication by e-mail) can have
                      security gaps. A complete protection of data against
                      access by third parties is not possible.
                      <br></br>
                      <br></br>By using this website, you consent to the
                      collection, processing and use of data in accordance with
                      the following description. This website can generally be
                      visited without registration. Data such as pages accessed
                      or names of files accessed, date and time are stored on
                      the server for statistical purposes without this data
                      being directly related to your person. Personal data, in
                      particular name, address or e-mail address are collected
                      as far as possible on a voluntary basis. Without your
                      consent, the data will not be passed on to third parties.
                    </p>
                  </div>
                  <div className="single-block mb-13">
                    <h3 className="gr-text-8 font-weight-bold">
                      Processing of personal data
                    </h3>
                    <p className="gr-text-9 pb-5">
                      Personal data is any information that relates to an
                      identified or identifiable person. A data subject is a
                      person about whom personal data is processed. Processing
                      includes any handling of personal data, regardless of the
                      means and procedures used, in particular the storage,
                      disclosure, acquisition, deletion, storage, modification,
                      destruction and use of personal data.
                      <br></br>
                      <br></br>We process personal data in accordance with Swiss
                      data protection law. Furthermore, we process personal data
                      - to the extent and insofar as the EU GDPR is applicable -
                      in accordance with the following legal bases in connection
                      with Art. 6 (1) GDPR:
                    </p>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        lit. a) Processing of personal data with the consent of
                        the data subject.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        lit. b) Processing of personal data for the fulfillment
                        of a contract with the data subject as well as for the
                        implementation of corresponding pre-contractual
                        measures.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        lit. c) Processing of personal data for the fulfillment
                        of a legal obligation to which we are subject under any
                        applicable law of the EU or under any applicable law of
                        a country in which the GDPR is applicable in whole or in
                        part.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        lit. d) Processing of personal data in order to protect
                        the vital interests of the data subject or another
                        natural person.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        lit. f) Processing of personal data to protect the
                        legitimate interests of us or of third parties, unless
                        the fundamental freedoms and rights and interests of the
                        data subject are overridden. Legitimate interests are in
                        particular our business interest in being able to
                        provide our website, information security, the
                        enforcement of our own legal claims and compliance with
                        Swiss law.
                      </li>
                    </ul>
                    <p className="gr-text-9 pb-5">
                      <br></br>
                      We process personal data for the duration required for the
                      respective purpose or purposes. In the case of longer-term
                      retention obligations due to legal and other obligations
                      to which we are subject, we restrict processing
                      accordingly.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for cookies
                    </h3>
                    <p className="gr-text-9 pb-5">
                      This website uses cookies. These are small text files that
                      make it possible to store specific information related to
                      the user on the user's terminal device while the user is
                      using the website. Cookies make it possible, in
                      particular, to determine the frequency of use and number
                      of users of the pages, to analyze behavior patterns of
                      page use, but also to make our offer more
                      customer-friendly. Cookies remain stored beyond the end of
                      a browser session and can be retrieved when you visit the
                      site again. If you do not wish this to happen, you should
                      set your Internet browser so that it refuses to accept
                      cookies.
                      <br></br>
                      <br></br>A general objection to the use of cookies used
                      for online marketing purposes can be declared for a large
                      number of the services, especially in the case of
                      tracking, via the U.S. site
                      http://www.aboutads.info/choices/ or the EU site
                      http://www.youronlinechoices.com/. Furthermore, the
                      storage of cookies can be achieved by disabling them in
                      the browser settings. Please note that in this case not
                      all functions of this online offer can be used.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for SSL/TLS encryption
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses SSL/TLS encryption for security reasons
                      and to protect the transmission of confidential content,
                      such as requests that you send to us as the site operator.
                      You can recognize an encrypted connection by the fact that
                      the address line of the browser changes from "http://" to
                      "https://" and by the lock symbol in your browser line.
                      <br></br>
                      <br></br>If SSL or TLS encryption is activated, the data
                      you transmit to us cannot be read by third parties.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Data transmission security (without SSL)
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      Please note that data transmitted over an open network
                      such as the Internet or an e-mail service without SSL
                      encryption can be viewed by anyone. You can recognize an
                      unencrypted connection by the fact that the address line
                      of the browser shows "http://" and no lock symbol is
                      displayed in your browser line. Information transmitted
                      over the Internet and content received online may be
                      transmitted over third-party networks. We cannot guarantee
                      the confidentiality of any communications or materials
                      transmitted over such open or third-party networks.
                      <br></br>
                      <br></br>If you disclose personally identifiable
                      information over an open network or third-party networks,
                      you should be aware that your information may be lost or
                      potentially accessed by third parties and, as a result,
                      the information may be collected and used without your
                      consent. While in many cases the individual data packets
                      are transmitted in encrypted form, the names of the sender
                      and recipient are not. Even if the sender and the
                      recipient reside in the same country, the data transfer
                      via such networks often takes place without controls even
                      via third countries, i.e. also via countries that do not
                      offer the same level of data protection as your country of
                      domicile. We assume no responsibility for the security of
                      your data during transmission over the Internet and
                      disclaim any liability for direct or indirect losses. We
                      ask you to use other means of communication should you
                      consider this necessary or reasonable for security
                      reasons.
                      <br></br>
                      <br></br>Despite extensive technical and organizational
                      security precautions, it is possible that data may be lost
                      or intercepted and/or manipulated by unauthorized persons.
                      As far as possible, we take appropriate technical and
                      organizational security measures to prevent this within
                      our system. However, your computer is outside the security
                      perimeter under our control. It is your responsibility as
                      a user to inform yourself about the necessary security
                      precautions and to take appropriate measures in this
                      regard. As the website operator, we are in no way liable
                      for any damage that you may suffer as a result of data
                      loss or manipulation.
                      <br></br>
                      <br></br>Data that you enter in online forms may be passed
                      on to authorized third parties for the purpose of order
                      processing and may be viewed and possibly processed by
                      them.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for server log files
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      The provider of this website automatically collects and
                      stores information in so-called server log files, which
                      your browser automatically transmits to us. These are:
                      browser type and browser version, operating system used,
                      referrer URL, host name of the accessing computer and time
                      of the server request. This data cannot be assigned to
                      specific persons. This data is not merged with other data
                      sources. We reserve the right to check this data
                      retrospectively if we become aware of specific indications
                      of illegal use.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Third party services
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website may use Google Maps for embedding maps,
                      Google Invisible reCAPTCHA for protection against bots and
                      spam, and YouTube for embedding videos.
                      <br></br>
                      <br></br>These services of the American Google LLC use,
                      among other things, cookies and, as a result, data is
                      transferred to Google in the USA, although we assume that
                      no personal tracking takes place in this context solely
                      through the use of our website.
                      <br></br>
                      <br></br>Google has undertaken to ensure adequate data
                      protection in accordance with the US-European and the
                      US-Swiss Privacy Shield.
                      <br></br>
                      <br></br>Further information can be found in Google's
                      privacy policy.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for contact form
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      If you send us inquiries via the contact form, your data
                      from the inquiry form, including the contact data you
                      provided there, will be stored by us for the purpose of
                      processing the inquiry and in case of follow-up questions.
                      We do not pass on this data without your consent.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for newsletter data
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      If you would like to receive the newsletter offered on
                      this website, we require an e-mail address from you as
                      well as information that allows us to verify that you are
                      the owner of the e-mail address provided and that you
                      agree to receive the newsletter. Further data will not be
                      collected. We use this data exclusively for sending the
                      requested information and do not pass it on to third
                      parties (except the newsletter service provider).
                      <br></br>
                      <br></br>You can revoke your consent to the storage of the
                      data, the e-mail address and their use for sending the
                      newsletter at any time, for example via the "unsubscribe
                      link" in the newsletter.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for comment & rating function on this
                      website
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      For the comment and rating function on this website, in
                      addition to your comment or rating, details of when the
                      comment or rating was created, your e-mail address and, if
                      you do not post anonymously, the username you have chosen
                      will be stored and can be displayed publicly. Only your
                      email address will never be displayed publicly.
                      <br></br>
                      <br></br>Our comment and rating function stores the IP
                      addresses of users who post comments or ratings. Since we
                      do not check comments and ratings every time on our site
                      before they are activated, we need this data to be able to
                      take action against the author in the event of legal
                      violations such as insults or propaganda or violations of
                      our terms of use.
                      <br></br>
                      <br></br>We may also contact you and you may receive
                      verification, confirmation or review emails and notices.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Chargeable services
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      For the provision of chargeable services, we request
                      additional data, such as payment details, in order to be
                      able to execute your order. We store this data in our and
                      payment provider systems until the legal retention periods
                      have expired.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Use of Google Maps
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses the offer of Google Maps. This allows us
                      to display interactive maps directly on the website and
                      enables you to comfortably use the map function. By
                      visiting the website, Google receives the information that
                      you have accessed the corresponding subpage of our
                      website. This occurs regardless of whether Google provides
                      a user account through which you are logged in or whether
                      there is no user account. If you are logged in to Google,
                      your data will be directly assigned to your account. If
                      you do not want the assignment with your profile at
                      Google, you must log out before activating the button.
                      Google stores your data as usage profiles and uses them
                      for the purposes of advertising, market research and/or
                      demand-oriented design of its website. Such an evaluation
                      is carried out in particular (even for users who are not
                      logged in) to provide needs-based advertising and to
                      inform other users of the social network about your
                      activities on our website. You have the right to object to
                      the creation of these user profiles, whereby you must
                      contact Google to exercise this right. For more
                      information on the purpose and scope of data collection
                      and processing by Google, as well as further information
                      on your rights in this regard and settings options for
                      protecting your privacy, please visit:
                      www.google.de/intl/de/policies/privacy.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Use of Google reCAPTCHA
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses the reCAPTCHA service of Google Ireland
                      Limited (Gordon House, Barrow Street Dublin 4, Ireland
                      "Google"). The query serves the purpose of distinguishing
                      whether the input is made by a human or by automated,
                      machine processing. The query includes the sending of the
                      IP address and possibly other data required by Google for
                      the reCAPTCHA service to Google. For this purpose, your
                      input is transmitted to Google and further used there.
                      However, your IP address will be shortened beforehand by
                      Google within member states of the European Union or in
                      other contracting states of the Agreement on the European
                      Economic Area. Only in exceptional cases will the full IP
                      address be transmitted to a Google server in the USA and
                      shortened there. On behalf of the operator of this
                      website, Google will use this information to evaluate your
                      use of this service. The IP address transmitted by your
                      browser as part of reCaptcha will not be merged with other
                      data from Google. Your data may also be transmitted to the
                      USA in the process. For data transfers to the USA, there
                      is an adequacy decision of the European Commission, the
                      "Privacy Shield". Google participates in the "Privacy
                      Shield" and has submitted to the requirements. By pressing
                      the query, you consent to the processing of your data. The
                      processing is based on Art. 6 (1) lit. a DSGVO with your
                      consent. You can revoke your consent at any time without
                      affecting the lawfulness of the processing carried out on
                      the basis of the consent until revocation.
                      <br></br>
                      <br></br>You can find more information about Google
                      reCAPTCHA and the associated privacy policy at:
                      https://policies.google.com/privacy?hl=de
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for Google Analytics
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses Google Analytics, a web analytics
                      service provided by Google Ireland Limited. If the data
                      controller on this website is located outside the European
                      Economic Area or Switzerland, then the Google Analytics
                      data processing is carried out by Google LLC. Google LLC
                      and Google Ireland Limited are hereinafter referred to as
                      "Google".
                      <br></br>
                      <br></br>The statistics obtained enable us to improve our
                      offer and make it more interesting for you as a user. This
                      website also uses Google Analytics for a cross-device
                      analysis of visitor flows, which is carried out via a user
                      ID. If you have a Google user account, you can deactivate
                      the cross-device analysis of your usage in the settings
                      there under "My data", "Personal data".
                      <br></br>
                      <br></br>The legal basis for the use of Google Analytics
                      is Art. 6 para. 1 p. 1 lit. f DS-GVO. The IP address
                      transmitted by your browser as part of Google Analytics
                      will not be merged with other data from Google. We would
                      like to point out that on this website Google Analytics
                      has been extended by the code "_anonymizeIp();" to ensure
                      anonymized collection of IP addresses. This means that IP
                      addresses are processed in abbreviated form, which means
                      that they cannot be linked to a specific person. If the
                      data collected about you is related to a person, this is
                      immediately excluded and the personal data is deleted
                      immediately.
                      <br></br>
                      <br></br>Only in exceptional cases will the full IP
                      address be transferred to a Google server in the USA and
                      shortened there. On behalf of the operator of this
                      website, Google will use this information for the purpose
                      of evaluating your use of the website, compiling reports
                      on website activity and providing other services relating
                      to website activity and internet usage to the website
                      operator. For the exceptional cases in which personal data
                      is transferred to the USA, Google has submitted to the
                      EU-US Privacy Shield,
                      https://www.privacyshield.gov/EU-US-Framework.
                      <br></br>
                      <br></br>Google Analytics uses cookies. The information
                      generated by the cookie about your use of this website is
                      usually transmitted to a Google server in the USA and
                      stored there. You may refuse the use of cookies by
                      selecting the appropriate settings on your browser,
                      however please note that if you do this you may not be
                      able to use the full functionality of this website. You
                      can also prevent the collection of data generated by the
                      cookie and related to your use of the website (including
                      your IP address) to Google and the processing of this data
                      by Google by downloading and installing the browser plugin
                      available at the following link:
                      https://tools.google.com/dlpage/gaoptout.
                      <br></br>
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for the use of Google Web Fonts
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses so-called web fonts provided by Google
                      for the uniform display of fonts. When you call up a page,
                      your browser loads the required web fonts into its browser
                      cache in order to display texts and fonts correctly. If
                      your browser does not support web fonts, a standard font
                      is used by your computer.
                      <br></br>
                      <br></br>You can find more information on Google Web Fonts
                      at https://developers.google.com/fonts/faq and in Google's
                      privacy policy: https://www.google.com/policies/privacy/
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Google Tag Manager
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      Google Tag Manager is a solution that allows us to manage
                      so-called website tags via an interface, enabling us to
                      integrate Google Analytics and other Google marketing
                      services into our online offering, for example. The Tag
                      Manager itself, which implements the tags, does not
                      process any personal data of the users. With regard to the
                      processing of users' personal data, please refer to the
                      following information on Google services. Usage
                      guidelines:
                      https://www.google.com/intl/de/tagmanager/use-policy.html.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for the use of Hotjar
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses the service of Hotjar to improve the
                      user experience. Hotjar Ltd Hotjar Ltd is a European
                      company based in Malta (Hotjar Ltd, Level 2, St Julians
                      Business Centre, 3, Elia Zammit Street, St Julians STJ
                      1000, Malta, Europe). Mouse clicks as well as mouse and
                      scroll movements can be recorded. Likewise, keystrokes
                      performed on this website can be recorded. Personalized
                      information is not recorded. Hotjar uses a tracking code
                      to collect and transmit your data. When you visit our
                      website, the Hotjar tracking code automatically collects
                      data based on your activity and stores it on Hotjar
                      servers (located in Ireland). In addition, the cookies
                      placed by the website on your computer or terminal device
                      also collect data. For more information on how Hotjar
                      works, visit this page: https://www.hotjar.com/privacy.
                      <br></br>
                      <br></br>If you wish to object to the collection of data
                      by Hotjar (opt-out), please click here:
                      https://www.hotjar.com/opt-out.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for Facebook
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses functions of Facebook Inc, 1601 S.
                      California Ave, Palo Alto, CA 94304, USA . When you call
                      up our pages with Facebook plug-ins, a connection is
                      established between your browser and the Facebook servers.
                      In the process, data is already transmitted to Facebook.
                      If you have a Facebook account, this data can be linked to
                      it. If you do not want this data to be associated with
                      your Facebook account, please log out of Facebook before
                      visiting our site. Interactions, in particular the use of
                      a comment function or the clicking of a "Like" or "Share"
                      button are also passed on to Facebook. You can learn more
                      at https://de-de.facebook.com/about/privacy.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for Twitter
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      This website uses functions of Twitter, Inc, 1355 Market
                      St, Suite 900, San Francisco, CA 94103, USA. When you call
                      up our pages with Twitter plug-ins, a connection is
                      established between your browser and the servers of
                      Twitter. In the process, data is already transferred to
                      Twitter. If you have a Twitter account, this data can be
                      linked to it. If you do not want this data to be
                      associated with your Twitter account, please log out of
                      Twitter before visiting our site. Interactions, in
                      particular clicking on a "re-tweet" button, are also
                      passed on to Twitter. You can learn more at
                      https://twitter.com/privacy.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for Instagram
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      On our website, functions of the service Instagram are
                      integrated. These functions are offered by Instagram Inc,
                      1601 Willow Road, Menlo Park, CA, 94025, USA integrated.
                      If you are logged into your Instagram account, you can
                      link the content of our pages to your Instagram profile by
                      clicking on the Instagram button. This allows Instagram to
                      associate the visit to our pages with your user account.
                      We would like to point out that we, as the provider of the
                      pages, have no knowledge of the content of the transmitted
                      data or its use by Instagram. For more information, please
                      see the privacy policy of Instagram:
                      http://instagram.com/about/legal/privacy/
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Social share function
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      When using social share functions and buttons, it is
                      possible that the corresponding social platform receives
                      data transmitted from you. We are not aware of what data
                      is transmitted in the process.
                    </p>
                  </div>
                  <div className="single-block mb-13">
                    <h3 className="gr-text-8 font-weight-bold">
                      External payment service providers
                    </h3>
                    <p className="gr-text-9 pb-5">
                      This website uses external payment service providers
                      through whose platforms users and we can make payment
                      transactions. For example via
                    </p>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Bexio AG (https://www.bexio.com/de-CH/datenschutz)
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Paypal
                        (https://www.paypal.com/de/webapps/mpp/ua/privacy-full)
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Stripe (https://stripe.com/ch/privacy)
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Apple Pay (https://support.apple.com/de-ch/ht203027)
                      </li>
                    </ul>
                    <p className="gr-text-9 pb-5">
                      <br></br>
                      In the context of the performance of contracts, we use the
                      payment service providers on the basis of the Swiss Data
                      Protection Ordinance and, where necessary, Art. 6 para. 1
                      lit. b. EU-DSGVO. Furthermore, we use external payment
                      service providers on the basis of our legitimate interests
                      pursuant to the Swiss Data Protection Ordinance as well as
                      and to the extent necessary pursuant to Art. 6 para. 1
                      lit. f. EU-DSGVO in order to offer our users effective and
                      secure payment options.
                      <br></br>
                      <br></br>The data processed by the payment service
                      providers includes inventory data, such as the name and
                      address, bank data, such as account numbers or credit card
                      numbers, passwords, TANs and checksums, among others, as
                      well as the contract, totals and recipient-related
                      information. The information is required in order to carry
                      out the transactions. However, the data entered is only
                      processed by the payment service providers and stored with
                      them. We as the operator do not receive any information
                      about (bank) account or credit card, but only information
                      to confirm (accept) or reject the payment. Under certain
                      circumstances, the data is transmitted by the payment
                      service providers to credit agencies. The purpose of this
                      transmission is to check identity and creditworthiness. In
                      this regard, we refer to the terms and conditions and data
                      protection information of the payment service providers.
                      <br></br>
                      <br></br>For the payment transactions, the terms and
                      conditions and the privacy policy of the respective
                      payment service providers apply, which can be accessed
                      within the respective website or transaction applications.
                      We also refer to these for the purpose of further
                      information and assertion of revocation, information and
                      other data subject rights.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for YouTube
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      Functions of the "YouTube" service are integrated on this
                      website. "YouTube" is owned by Google Ireland Limited, a
                      company incorporated and operated under the laws of
                      Ireland with its registered office at Gordon House, Barrow
                      Street, Dublin 4, Ireland, which operates the services in
                      the European Economic Area and Switzerland.
                      <br></br>
                      <br></br>Your legal agreement with "YouTube" consists of
                      the terms and conditions found at the following link:
                      https://www.youtube.com/static?gl=de&template=terms&hl=de.
                      These Terms constitute a legally binding agreement between
                      you and "YouTube" regarding your use of the Services.
                      Google's Privacy Policy explains how "YouTube" treats and
                      protects your personal information when you use the
                      Service.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Privacy policy for Vimeo
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      Plugins of the video portal Vimeo of Vimeo, LLC, 555 West
                      18th Street, New York, New York 10011, USA are integrated
                      on this website. Each time you visit a page that offers
                      one or more Vimeo video clips, a direct connection is
                      established between your browser and a Vimeo server in the
                      USA. In the process, information about your visit and your
                      IP address are stored there. Through interactions with the
                      Vimeo plugins (e.g. clicking the start button), this
                      information is also transmitted to Vimeo and stored there.
                      The privacy policy for Vimeo with more detailed
                      information on the collection and use of your data by
                      Vimeo can be found in the privacy policy of Vimeo.
                      <br></br>
                      <br></br>If you have a Vimeo user account and do not want
                      Vimeo to collect data about you via this website and link
                      it to your membership data stored with Vimeo, you must log
                      out of Vimeo before visiting this website.
                      <br></br>
                      <br></br>In addition, Vimeo calls up the Google Analytics
                      tracker via an iFrame in which the video is called up.
                      This is Vimeo's own tracking, to which we have no access.
                      You can prevent tracking by Google Analytics by using the
                      deactivation tools that Google offers for some Internet
                      browsers. You can also prevent the collection of data
                      generated by Google Analytics and related to your use of
                      the website (including your IP address) to Google and the
                      processing of this data by Google by downloading and
                      installing the browser plugin available at the following
                      link: https://tools.google.com/dlpage/gaoptout?hl
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      General disclaimer
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      All information on our website has been carefully checked.
                      We make every effort to ensure that the information we
                      provide is up-to-date, correct and complete. Nevertheless,
                      the occurrence of errors can not be completely excluded,
                      so we can not guarantee the completeness, accuracy and
                      timeliness of information, including
                      journalistic-editorial nature. Liability claims regarding
                      damage caused by the use of any information provided,
                      including any kind of information which is incomplete or
                      incorrect, will therefore be rejected.
                      <br></br>
                      <br></br>The publisher may change or delete texts at his
                      own discretion and without notice and is not obliged to
                      update the contents of this website. The use of or access
                      to this website is at the visitor's own risk. The
                      publisher, its clients or partners are not responsible for
                      damages, such as direct, indirect, incidental,
                      consequential or punitive damages, allegedly caused by the
                      visit of this website and consequently assume no liability
                      for such damages.
                      <br></br>
                      <br></br>The publisher also accepts no responsibility or
                      liability for the content and availability of third-party
                      websites that can be accessed via external links on this
                      website. The operators of the linked sites are solely
                      responsible for their content. The publisher thus
                      expressly distances itself from all third-party content
                      that may be relevant under criminal or liability law or
                      that may offend common decency.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">Changes</h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      We may change this privacy policy at any time without
                      prior notice. The current version published on our website
                      applies.
                    </p>
                  </div>
                  <div className="single-block pb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Questions for the data protection officer
                    </h3>
                    <p className="gr-text-9 pb-5 mb-0">
                      If you have any questions about data protection, please
                      send us an e-mail or contact the person in our
                      organization responsible for data protection listed at the
                      beginning of this privacy statement directly.
                      <br></br>
                      <br></br>
                      <br></br>
                      Should individual provisions or formulations of this
                      privacy policy be or become invalid, this shall not affect
                      the content or validity of the remaining provisions. In
                      the event of disputes, Swiss law applies exclusively and
                      the place of jurisdiction is Switzerland, Brig.
                      <br></br>
                      <br></br>
                      Brig-Glis, 10.09.2021
                      <br></br>
                      <br></br>
                      Credits: SwissAnwalt
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Imprint;
